
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import LayoutDefault from '@/layouts/LayoutDefault.vue';

import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

interface Location {
	coordinates: number[];
	type: string;
}

interface Property {
	_id: string;
	keyId: number;
	value: string;
}

interface ILocationData {
	location: Location;
	_id: string;
	deviceKey: string;
	timestamp: number;
	version: string;
	eventId: number;
	longitude: number;
	latitude: number;
	altitude: number;
	angle: number;
	satellites: number;
	speed: number;
	properties: Property[];
	__v: number;
}

// TODO:
// - Load all assets location data
// - Store in

export default defineComponent({
	name: 'Home',
	components: { LayoutDefault },
	setup() {},
	data() {
		return {
			firstLoad: true,
			location: [0, 0],
			center: [0, 0],
			autofocus: true,
			realtime: true,
			locationData: {} as ILocationData,
			map: {} as mapboxgl.Map,
			ivl: 0,
			selectedDevice: 'imei-352625693507368',
		};
	},
	watch: {
		autofocus(val, oldVal) {
			if (val) {
				console.log('FOCUSED');
				this.focusOnAsset();
			} else {
				console.log('NOT FOCUSED');
			}
		},
		realtime(val, oldVal) {
			if (val) {
				console.log('STARTED');
				this.updateLocation();
			} else {
				console.log('STOPPED');
				clearInterval(this.ivl);
			}
		},
	},

	methods: {
		toggleFullScreen() {
			// if already full screen; exit
			// else go fullscreen
			if (
				document.fullscreenElement // ||
				// document.webkitFullscreenElement ||
				// document.mozFullScreenElement ||
				// document.msFullscreenElement
			) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
					// } else if (document.mozCancelFullScreen) {
					// 	document.mozCancelFullScreen();
					// } else if (document.webkitExitFullscreen) {
					// 	document.webkitExitFullscreen();
					// } else if (document.msExitFullscreen) {
					// 	document.msExitFullscreen();
				}
			} else {
				var element = document.getElementById('map');
				if (element) {
					if (element.requestFullscreen) {
						element.requestFullscreen();
						// } else if (element.mozRequestFullScreen) {
						// 	element.mozRequestFullScreen();
						// } else if (element.webkitRequestFullscreen) {
						// 	element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
						// } else if (element.msRequestFullscreen) {
						// 	element.msRequestFullscreen();
					}
				}
			}
		},
		async createMap() {
			try {
				mapboxgl.accessToken =
					'pk.eyJ1IjoibWljaGFlbGJyYW5kdCIsImEiOiJja3I5Y3UzNG4zcmZlMnJueHVqM21wY3N1In0.qnLG4luWH7btrLueLoorZw';

				this.map = new mapboxgl.Map({
					container: 'map',
					style: 'mapbox://styles/mapbox/streets-v11',
					center: [0, 0],
				});
			} catch (err) {
				console.log('map error', err);
			}
		},
		async loadLocationData() {
			try {
				const response = await axios.get<ILocationData>(
					`http://api.bransen.dk:5000/location/latest/${this.selectedDevice}`
				);

				if (this.firstLoad) {
					this.addMarker();
				}

				this.firstLoad = false;

				this.locationData = response.data;
				this.location = this.locationData.location.coordinates;
			} catch (err) {
				console.log(err);
			}
		},
		focusOnAsset() {
			this.map.flyTo({
				center: [
					this.locationData.longitude || 0,
					this.locationData.latitude || 0,
				],
				speed: 2,
				zoom: 15,
			});
		},
		async updateLocation() {
			clearInterval(this.ivl);
			this.ivl = await setInterval(async () => {
				await this.loadLocationData();

				this.updateMarker();

				if (this.autofocus && this.firstLoad == false) {
					this.focusOnAsset();
				}
			}, 5000);
		},
		addMarker() {
			this.map.addLayer({
				id: 'opel-layer',
				type: 'symbol',
				source: 'asset',
				layout: {
					'icon-image': 'car-15',
					'icon-size': 2.0,
				},
			});

			// this.map.addLayer({
			// 	id: 'earthquakes-layer',
			// 	type: 'circle',
			// 	source: 'earthquakes',
			// 	paint: {
			// 		'circle-radius': 8,
			// 		'circle-stroke-width': 2,
			// 		'circle-color': 'red',
			// 		'circle-stroke-color': 'white',
			// 	},
			// });
		},
		updateMarker() {
			var location = this.locationData.location;

			var hest = {
				type: 'FeatureCollection',
				features: [
					{
						type: 'Feature',
						properties: {
							name: 'Open Astra',
							description: '',
						},
						geometry: location,
					},
				],
			} as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

			const source = this.map.getSource('asset') as GeoJSONSource;
			source.setData(hest);
		},
	},
	async mounted() {
		await this.updateLocation();
		this.createMap();

		this.map.on('load', () => {
			this.map.addSource('asset', {
				type: 'geojson',
				data: {
					// DATA COULD BE SET TO AN GeoJSON return URL (with all the feature)
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [0, 0],
					},
					properties: {
						name: 'Min Opel',
					},
				},
			});
		});
	},
});
